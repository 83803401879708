<template>
  <!-- 简历 -->
  <div class="detail__wrap" v-show="visible">
    <el-breadcrumb separator-class="el-icon-arrow-right" class="crumbs__wrap">
      <el-breadcrumb-item :to="{ path: '/jobs' }">岗位列表</el-breadcrumb-item>
      <el-breadcrumb-item>岗位详情</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 表格 -->
    <div class="section shadow jianli">
      <div>
        <el-button class="backBtn" plain icon="el-icon-d-arrow-left" @click="toggle(false)" size="mini">返回</el-button>
      </div>
      <div class="main">
        <el-form
          v-loading="loading"
          element-loading-text="加载中…"
          ref='elFormDom'
          label-width='110px'
          label-position='top'
          :model='form.data'
          :rules='form.rules'
        >
          <el-row :gutter="30">
            <el-col :span="12">
              <el-form-item prop="title" label="岗位名称">
                <el-input v-model="form.data.title" placeholder="请输入" clearable ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item prop="job_id" label="岗位类型">
                <el-cascader
                  v-model="form.data.job_id"
                  :options="categoryArr"
                  :props="{ emitPath: false, value:'id', label:'name' }"
                >
                </el-cascader>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item prop="major_id" label="专业类型">
                <el-select v-model="form.data.major_id" placeholder="请选择">
                  <el-option
                    v-for="item in majorArr"
                    :label="item.name"
                    :value="item.id"
                    :key="item.id" />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item prop="salary_start" label="薪资待遇">
                <el-row>
                  <el-col :span="11">
                    <el-input v-model="form.data.salary_start" placeholder="薪资待遇下限" @input="form.data.salary_start=form.data.salary_start.replace(/[^\d^\.]/g,'')" >
                       <template slot="append">千</template>
                    </el-input>
                  </el-col>
                  <el-col :span="2" style="text-align: center;">
                    <span>至</span>
                  </el-col>
                  <el-col :span="11">
                    <el-input v-model="form.data.salary_end" placeholder="薪资待遇上限" @input="form.data.salary_end=form.data.salary_end.replace(/[^\d^\.]/g,'')" >
                       <template slot="append">千</template>
                    </el-input>
                  </el-col>
                </el-row>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item prop="area_arr" label="省市区">
                <el-cascader
                  v-model="form.data.area_arr"
                  :options="areaArr"
                >
                </el-cascader>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item prop="working_address" label="工作地址">
                <el-input v-model="form.data.working_address"  placeholder="请输入" clearable ></el-input>
                <el-input style="margin-top:10px" v-model="form.data.address"  placeholder="请补充门牌号信息" clearable ></el-input>
              </el-form-item>
            </el-col>
            <!-- <el-col :span="12">
              <el-form-item prop="address" label="详细地址">
                <el-input v-model="form.data.address"  placeholder="请输入" clearable ></el-input>
              </el-form-item>
            </el-col> -->
            
          </el-row>
          <el-row :gutter="30">
            <el-col :span="12">
              <el-form-item prop="education" label="学历要求">
                <el-select v-model="form.data.education" placeholder="请选择">
                  <el-option
                    v-for="item in diplomaOpt"
                    :label="item.name"
                    :value="item.id"
                    :key="item.id" />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item prop="work_age" label="经验要求">
                <el-select v-model="form.data.work_age" placeholder="请选择">
                  <el-option label="无经验" :value="1"></el-option>
                  <el-option label="1年以下" :value="2"></el-option>
                  <el-option label="1年以上" :value="3"></el-option>
                  <el-option label="应届毕业生" :value="4"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item prop="type" label="职业类型">
                <el-select v-model="form.data.type" placeholder="请选择">
                  <el-option label="全职" :value="1"></el-option>
                  <el-option label="实习" :value="2"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item prop='weal' label='福利待遇'>
                <div v-for="(item,index) in wealArr" :key="index" class="wealWrap">
                  <div class="weal-title">{{item.name}}:</div>
                  <el-checkbox-group v-model="form.data.weal" size="mini" @change="changeWeal($event)" >
                    <el-checkbox :label="w.id" v-for="w in item.child" :key="w.id">{{w.name}}</el-checkbox>
                  </el-checkbox-group>
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item prop="duty" label="工作职责">
                <el-input
                  type="textarea"
                  :autosize="{ minRows: 2, maxRows: 4}"
                  placeholder="请输入"
                  v-model="form.data.duty">
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item prop="demand" label="责任要求">
                <el-input
                  type="textarea"
                  :autosize="{ minRows: 2, maxRows: 4}"
                  placeholder="请输入"
                  v-model="form.data.demand">
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row type="flex" justify="center">
            <el-button @click='toggle(false)'>取消</el-button>
            <el-button type="primary" :loading="form.loading" :disabled="form.loading" @click='confirm'>确定</el-button>
          </el-row>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import common from '@/util'
export default {
  name: 'JobsEdit',
  props:['categoryArr','areaArr'],
  data() {
    return {
      visible: false,
      loading: false,
      form: {
        loading: false,
        data: {
          id: '',
          title: '',
          category: '',
          job_id: '',
          major_id: '',
          salary_start: '',
          salary_end: '',
          education: '',
          work_age: '',
          type: '',
          duty: '',
          area_arr: [],
          address: '',
          working_address: '',
          demand: '',
          welfare: '',
          weal: [],
        },
        rules: {
          title: [{ required: true, message:'必填项', trigger: 'change'}],
          category: [{ required: true, message:'必填项', trigger: 'change'}],
          job_id: [{ required: true, message:'必填项', trigger: 'change'}],
          major_id: [{ required: true, message:'必填项', trigger: 'change'}],
          salary_start: [{ required: true, message:'必填项', trigger: 'change'}],
          salary_end: [{ required: true, message:'必填项', trigger: 'change'}],
          education: [{ required: true, message:'必填项', trigger: 'change'}],
          work_age: [{ required: true, message:'必填项', trigger: 'change'}],
          type: [{ required: true, message:'必填项', trigger: 'change'}],
          duty: [{ required: true, message:'必填项', trigger: 'change'}],
          address: [{ required: true, message:'必填项', trigger: 'change'}],
          working_address: [{ required: true, message:'必填项', trigger: 'change'}],
          demand: [{ required: true, message:'必填项', trigger: 'change'}],
          area_arr: [{ required: true, message:'必填项', trigger: 'change'}],
        }
      },
      diplomaOpt: [
        {id: 1,name: '中专'},
        {id: 2,name: '大专'},
        {id: 3,name: '本科'},
        {id: 4,name: '硕士'}
      ],
      majorArr: [],
      wealArr: []
     
    }
  },
  methods: {
    getDetail(row) {
      this.$http.get('/admin/job/detail',{params: {id:row.id}}).then(res => {
        if(res.code == 1) {
          // this.form.data = res.data;
          // common.deepClone(this.form.data, row)
          common.deepClone(this.form.data, res.data)
          this.form.data.weal = res.data.welfare.map(v => Number(v))
          this.form.data.area_arr = [res.data.province,res.data.city,res.data.area]
        } else {
          this.$message.error(res.msg)
        }
      })
      this.getMajor();
      this.getWeal();
    },
    reset(done) {
      done && done()
    },
    toggle(show) {
      this.visible = show
      !show && this.reset()
    },
    toStr(data, key) {
      return data[key]
    },
    // 获取专业类型
    getMajor() {
      // /common/college/allMajor
      this.$http.get('/common/college/getMajor',{params: {page:1,count:1000}}).then(res => {
        if(res.code == 1) {
          this.majorArr = res.data.list;
          let str = {id:0,name:'不限'}
          this.majorArr.unshift(str)
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    // 获取福利
    getWeal() {
      this.$http.post('/common/param/weal',{}).then(res => {
        if(res.code == 1) {
          this.wealArr = res.data;
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    // 福利
    changeWeal(value) {
      this.form.data.weal = value
    },
    confirm() {
      this.$refs.elFormDom.validate(valid => {
        if(valid) {
           let _area_arr = this.form.data.area_arr;
          let _params = {
            id: this.form.data.id,
            title: this.form.data.title,
            category: this.form.data.category,
            job_id: this.form.data.job_id,
            major_id: this.form.data.major_id,
            salary_start: this.form.data.salary_start,
            salary_end: this.form.data.salary_end,
            education: this.form.data.education,
            work_age: this.form.data.work_age,
            type: this.form.data.type,
            duty: this.form.data.duty,
            province: _area_arr[0],
            city: _area_arr[1],
            area: _area_arr[2],
            address: this.form.data.address,
            working_address: this.form.data.working_address,
            demand: this.form.data.demand,
            welfare: this.form.data.weal.join("|"),
          }
         
          this.form.loading = true;
          this.$http.post('/admin/job/edit',_params).then(res => {
            if(res.code == 1) {
              this.$message.success('操作成功！')
              this.toggle(false)
              this.$emit('refresh')
            }else {
              this.$message.error(res.msg)
            }
          }).finally(() => {
            this.form.loading = false;
          })
        }
      })
    }
   
  }
}
</script>

<style scoped lang="scss">
  .detail__wrap {
    background-color: #f5f5f5;
    padding: 20px;
    z-index: 999 !important;
    overflow-y: scroll;
    @include position($t: 0, $r: 0, $b: 0, $l: 0);

    .crumbs__wrap{
      margin-bottom: 20px;
    }

    .main {
      width: 800px;
      background-color: #fff;
      padding: 20px;
    }
  }

  .flex {
    display: flex;
  }
  .mt50 {
    margin-top: 50px;
  }
  .fs16{
    font-size: 16px;
    font-weight: bold;
    color: #333;
  }

  .main {
    width: 800px;
    background-color: #fff;
    padding: 20px;
  }
  .jianli {
    background-color: #fff;
    padding: 20px !important;
    color: #656565;

    .text {
      width: 60%;
      line-height: 30px;
    }
    .title {
      background: url('~@/assets/img/points.png');
      background-repeat: no-repeat;
      background-position-y: 6px;
      padding-left: 30px;
      height: 30px;
      line-height: 30px;
    }
    .picture {
      margin-right: 15px;
    }
    .fontB {
      font-size: 18px;
      font-weight: bold;
      color: #333;
    }
    .section {
      .user-info {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        position: relative;
        
        .userName {
          font-weight: bold;
        }
        .degree span {
          margin-right: 16px;
        }
        .degree span:not(:first-child)::before {
          position: absolute;
          height: 15px;
          width: 2px;
          background-color: #656565;
          margin-top: 2px;
          margin-left: -8px;
          content: "";
        }
      }
      .date {
        height: 30px;
        line-height: 30px;
        background: #30c3b1;
        color: #fff;
        padding: 5px 10px;
        border-radius: 20px 0 0 20px;
        position: absolute;
        right: 20px;

      }
    }
    .work_suffer {
      margin-top:30px;
      border-top:solid 1px #eee;
    }
    .work_suffer:first-child {
      border-top:none;
    }
  }
</style>