<template>
  <div class="proposal__wrap">
    <div class="section shadow filter__wrap">
      <div style="margin-bottom:10px;">
        <el-radio-group v-model="table.params.status" @change="handleTabs">
          <el-radio-button :label="1">正在招聘{{table.total1}}</el-radio-button>
          <el-radio-button :label="2">结束招聘{{table.total2}}</el-radio-button>
        </el-radio-group>
      </div>

      <div class="flex">
        <el-form ref="elFormDom" inline :model="table.params" size="small">
          <el-form-item label="企业、岗位名称" prop="keyword">
            <el-input clearable v-model.trim="table.params.keyword" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="岗位类别" prop="job_id">
            <el-cascader
              v-model="table.params.job_id"
              :options="categoryArr"
              :props="{ emitPath: false, value:'id', label:'name' }"
            >
            </el-cascader>
          </el-form-item>
          <el-form-item label="所在地区" prop="area_arr">
            <el-cascader
              v-model="table.params.area_arr"
              :options="areaArr"
              :props="{ checkStrictly: true }"
            >
            </el-cascader>
          </el-form-item>
          <el-form-item label="状态" prop="is_recommend">
            <el-select v-model="table.params.is_recommend" placeholder="请选择" clearable>
              <el-option label="全部" value=""></el-option>
              <el-option label="已推荐" :value="1"></el-option>
              <el-option label="未推荐" :value="2"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" icon="el-icon-search" @click="handleSearch">查询 </el-button>
            <el-button icon="el-icon-refresh-right" @click="reset">重置</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div style="margin-bottom:10px;" v-if="table.params.status == 1">
      <el-button l-button type="primary" icon="el-icon-share" size="small" @click="handleBatch">批量推荐</el-button>
    </div>
    <!-- 表格 -->
    <div class="table__wrap">
      <VTable
        has-pagionation
        title=''
        addText='添加'
        :field="table.params.status == 1 ? field : field2"
        :loading="table.loading"
        :data="table.data"
        :page='table.params.page'
        :pageSize='table.params.count'
        :total='table.total'
        :tree-props="{children: 'children', hasChildren: 'has_child'}"
        :hasPagionation="true"
        :default-expand-all="true"
        :showOverflowTooltip="true"
        :showSelect="true"
        @handleSizeChange="handleSizeChange"
        @handleCurrentChange="handleCurrentChange"
        @handleSelectionChange="handleSelectionChange"
      >
        <template v-slot:ali_job_status="{row}" v-if="table.params.status == 1">
          <!-- ali_job_status 支付宝岗位状态：1-未推荐；2-已推荐 -->
          <el-button :type="row.ali_job_status == 2 ? 'primary' : ''" icon="el-icon-share" size="mini" @click="handlePush(row)">{{row.ali_job_status == 2 ? '已推荐' : '未推荐'}}</el-button>
        </template>
        <template v-slot:action="{row}">
          <el-button type="text" size="mini" icon="el-icon-edit" @click="showEdit(row)">修改信息</el-button> 
          <el-button type="text" size="mini" :icon="table.params.status == 1 ? 'el-icon-bottom' : 'el-icon-top'" @click="handleOption(row)">{{table.params.status == 1 ? '下架' : '上架'}}</el-button> 
        </template>
      </VTable> 
    </div>

    <edit ref="edit" :categoryArr="categoryArr" :areaArr="areaArr" @refresh="getTable"></edit>

  </div>
</template>

<script>
import VTable from '@/components/VTable'
import Edit from './components/Edit.vue'
import { mixinTable } from '@/mixins/table.js'
import Vue from 'vue'
import { 
    Notification,
} from 'element-ui'
//应用插件
Vue.prototype.$notify = Notification;

export default {
  name: 'ReportList',
  mixins:[mixinTable],
  components: {
    VTable,
    Edit,
  },
  data() {
    return {
      field: [
        { name: "title", label: "职位标题", hidden: false },
        { name: "job_id_str", label: "岗位类型", hidden: false },
        { name: "company_name", label: "所属企业", hidden: false },
        { name: "salary_str", label: "薪资待遇/元", hidden: false },
        { name: "num", label: "投递人数/人", hidden: false },
        { name: "update_time", label: "更新时间", hidden: false },
        { name: "ali_job_status", label: "状态", hidden: true },
        { name: "action", label: "操作", width: "160", hidden: false }
      ],
      field2: [
        { name: "title", label: "职位标题", hidden: false },
        { name: "job_id_str", label: "岗位类型", hidden: false },
        { name: "company_name", label: "所属企业", hidden: false },
        { name: "salary_str", label: "薪资待遇/元", hidden: false },
        { name: "num", label: "投递人数/人", hidden: false },
        { name: "update_time", label: "更新时间", hidden: false },
        { name: "action", label: "操作", width: "160", hidden: false }
      ],
      table: {
        loading: false,
        params: {
          keyword: '',
          status: 1, //状态 1:启用 2:禁用
          job_id:'',
          province: '',
          city: '',
          area: '',
          area_arr: [],
          is_recommend: '',// 是否推荐 1:推荐 2:不推荐
          page: 1,
          count: 10
        },
        data: [],
        total: 0,
        total1: 0,
        total2: 0,
      },
      categoryArr: [],
      areaArr: [],
      multipleSelection: [],
    }
  },
  created() {
    this.getTable();
    this.getArea();
    this.getCategory();
  },
  methods: {
    getTable() {
      let _area_arr = this.table.params.area_arr;
      this.table.params.province = _area_arr[0];
      this.table.params.city = _area_arr[1];
      this.table.params.area = _area_arr[2];
      let _params = { ...this.table.params }
      delete _params.area_arr;
      this.$http.get('/admin/job/list',  {params: _params}).then(res => {
        if(res.code === 1) {
          this.table.data = res.data.list;
          this.table.total = res.data.total;
          this.table.total1 = res.data.total1;
          this.table.total2 = res.data.total2;
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    // 切换状态
    handleTabs() {
      this.table.params.page = 1;
      this.table.data = [];
      this.getTable();
    },
    // 获取省市区
    getArea() {
      this.$http.get('/common/district/all',{params:{}}).then(res => {
        if(res.code == 1) {
          this.areaArr = res.data.list;
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    // 获取岗位类别
    getCategory() {
      this.$http.get('common/param/category',{params:{}}).then(res => {
        if(res.code == 1) {
          this.categoryArr = res.data;
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    // 重置查询条件
    reset() {
      this.$refs.elFormDom.resetFields()
      this.getTable();
    },
    // 编辑
    showEdit(row) {
      let dom = this.$refs.edit
      dom.toggle(true)
      dom.getDetail(row)
      dom = null
    },
    
    // 批量选择数据
    handleSelectionChange(val) {
      this.multipleSelection = val
    },
    // 批量推送
    handleBatch() {
      if(this.multipleSelection.length > 0 && this.multipleSelection.length <= 20 ) {
        this.$confirm(`已选${this.multipleSelection.length}个，确认推荐至支付宝就业吗`,'批量推荐', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          let ids = this.multipleSelection.map(v => {
            return v.id
          }).join("|")
          this.pushFn(ids, 2)
        }).catch(() => {})
      } else if(this.multipleSelection.length > 20  ) {
        this.$message.warning('最大批量推送20条，请重新选择!')
      } else {
        this.$message.warning('请至少选择一条数据！')
      }
    },
    // 单条推荐
    handlePush(row) {
      // let str = row.ali_job_status == 2 ? '取消推荐':'推荐';
      this.$confirm(`${row.ali_job_status == 2 ? '确认取消推荐吗' : '确认推荐至支付宝就业吗'}`,`${row.ali_job_status == 2 ? '取消' : '推荐'}`, {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.pushFn(row.id, 1)
      }).catch(() => {})
    },
    pushFn(selIds,type) {
      this.$http.post('/admin/job/push', {ids: selIds}).then(res => {
        if(res.code == 1) {
            // type 1:单条推荐， 2批量推荐
            if(res.data.success == 0 && type == 2) {
              this.$message.error('推荐失败！')
            } else if(res.data.success > 0 && type == 2) {
              this.$notify({
                title: '提示',
                message: `推荐成功${res.data.success}条，失败${res.data.failure}条`
              });
            } else if(res.data.success > 0 && type == 1) {
              this.$message.success('操作成功')
            } else {
              this.$message.error('操作失败')
            }
          this.getTable();
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    // 下架、上架
    handleOption(row) {
      let str = this.table.params.status == 1 ? '下架' : '上架'
      this.$confirm(`请确认${str}操作？`,'提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http.post('/admin/job/operation', {id: row.id}).then(res => {
          if(res.code == 1) {
            this.getTable();
            this.$message.success('操作成功！')
          } else {
            this.$message.error(res.msg)
          }
        })
      }).catch(() => {})
    }
  }
}
</script>

<style scoped lang="scss">
  .flex {
    display: flex;
  }
  .proposal__wrap {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 20px;
    position: relative;

    .filter__wrap {
      padding: 10px;
      margin-bottom: 20px;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
    }

    .table__wrap {
      flex: 1;
      padding: 0;
      box-sizing: border-box;
    }
  }
</style>